import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono Play
 */
export const Play = ({
  className,
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
  ...props
}: IconType): JSX.Element => (
  <svg
    {...props}
    {...(className && { className })}
    width={width}
    height={height}
    viewBox="0 0 65 65"
    fill="none"
  >
    <g clipPath="url(#clip0_8593_359327)">
      <circle cx="32.5002" cy="32.8678" r="26.6667" fill={color} />
      <path
        d="M40.1273 33.0019L29.7687 39.9076C29.6951 39.9566 29.6096 39.9847 29.5213 39.9889C29.433 39.9931 29.3452 39.9733 29.2673 39.9316C29.1894 39.8899 29.1242 39.8278 29.0788 39.752C29.0333 39.6762 29.0093 39.5895 29.0093 39.5011V25.6896C29.0093 25.6012 29.0333 25.5145 29.0788 25.4386C29.1242 25.3628 29.1894 25.3008 29.2673 25.259C29.3452 25.2173 29.433 25.1975 29.5213 25.2017C29.6096 25.206 29.6951 25.234 29.7687 25.283L40.1273 32.1888C40.1942 32.2334 40.2491 32.2938 40.287 32.3648C40.325 32.4357 40.3448 32.5149 40.3448 32.5953C40.3448 32.6758 40.325 32.755 40.287 32.8259C40.2491 32.8968 40.1942 32.9573 40.1273 33.0019Z"
        fill="#F8F7F4"
      />
    </g>
    <defs>
      <clipPath id="clip0_8593_359327">
        <rect
          width="64"
          height="64"
          fill="white"
          transform="translate(0.5 0.867188)"
        />
      </clipPath>
    </defs>
  </svg>
);
